/** @jsx jsx */
import { jsx } from 'theme-ui'

const Container = ({ className, children }) => {
  return (
    <div
      className={className}
      sx={{
        maxWidth: '960px',
        p: 6,
        my: 0,
        mx: 'auto',
      }}
    >
      {children}
    </div>
  )
}

export default Container
