/** @jsx jsx */
import { jsx } from 'theme-ui'
import { Link } from 'gatsby'
import algoliasearch from 'algoliasearch/lite'
import { format } from 'date-fns'
import { InstantSearch, SearchBox, RefinementList, Hits, Panel } from 'react-instantsearch-dom'
import { getBlogUrl } from '../lib/helpers'
import Container from '../components/Container'
import SEO from '../components/SEO'
import LayoutContainer from '../containers/LayoutContainer'

const searchClient = algoliasearch('V2KL1DXL16', '380caab3599b1f52c2d390d5c0984571')

const langLabelMap = {
  en: 'English',
  ko: 'Korean',
}

const FacetTitle = ({ children }) => (
  <p
    sx={{
      color: 'blue',
      textTransform: 'uppercase',
      my: 0,
      mr: 0,
      ml: 1,
    }}
  >
    {children}
  </p>
)

function getPreviousUrl() {
  try {
    return decodeURIComponent(
      window &&
        window.location.search
          .split('?')[1]
          .split('&')
          .map(part => part.split('='))
          .find(([key]) => key === 'from')[1]
    )
  } catch (err) {
    return '/'
  }
}

const SearchPage = () => {
  return (
    <LayoutContainer>
      <SEO title="Search for posts" />
      <Container>
        <Link
          to={getPreviousUrl()}
          sx={{
            color: 'black',
            opacity: 0.6,
            '&:hover': {
              opacity: 0.8,
            },
          }}
        >
          <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
            <path
              fill="currentColor"
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            />
          </svg>
          <span hidden>Go Back Home</span>
        </Link>
        <div
          sx={{
            '& .ais-RefinementList-list': {
              listStyle: 'none',
              m: 0,
              p: 0,
            },
            '& .ais-RefinementList-checkbox': {
              mr: 1,
              ml: 0,
            },
            '& .ais-RefinementList-count': {
              display: 'inline-block',
              width: 6,
              color: 'blue',
              fontSize: 'sm',
              pl: 1,
            },
          }}
        >
          <InstantSearch indexName="posts" searchClient={searchClient}>
            <div sx={{ position: 'relative' }}>
              <SearchBox
                autoFocus
                translations={{
                  placeholder: 'Search by Algolia...',
                }}
                sx={{
                  mt: 4,
                  '& .ais-SearchBox-form': {
                    display: 'flex',
                    position: 'relative',
                  },
                  '& .ais-SearchBox-submit': {
                    display: 'none',
                  },
                  '& .ais-SearchBox-input': {
                    flexGrow: 1,
                    fontSize: 'base',
                    fontWeight: 'normal',
                    color: 'black',
                    m: 0,
                    py: 2,
                    pl: 10,
                    pr: 4,
                    border: 'lightBlack',
                    borderRadius: 'md',
                    '&:focus': { outline: 'none' },
                    appearance: 'none', // for Safari
                    '&::-webkit-search-cancel-button': {
                      // for Safari
                      display: 'none',
                    },
                  },
                  '& .ais-SearchBox-reset': {
                    position: 'absolute',
                    right: '0.2rem',
                    top: '0.75rem',
                    border: 0,
                    background: 'transparent',
                    cursor: 'pointer',
                    '& .ais-SearchBox-resetIcon': {
                      width: 3,
                      height: 3,
                      color: 'black',
                      opacity: 0.6,
                      '&:hover': {
                        opacity: 0.8,
                      },
                    },
                  },
                }}
              />
              <svg
                sx={{
                  width: 6,
                  height: 6,
                  opacity: 0.6,
                  position: 'absolute',
                  top: '0.45rem',
                  left: '0.5rem',
                }}
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 120 120"
              >
                <path
                  fill="#5468FF"
                  d="M104.2 0H15.8C7.1 0 0 7 0 15.8v88.4c0 8.7 7.1 15.8 15.8 15.8h88.4c8.7 0 15.8-7.1 15.8-15.8V15.8C120 7.1 112.9 0 104.2 0z"
                />
                <path
                  fill="#FFFFFF"
                  d="M30.3 41.9C30.4 41.9 30.4 41.9 30.3 41.9c1.3-1.7 2.6-3.3 4.1-4.7 1.5-1.5 3-2.8 4.7-4 0.3-0.2 0.4-0.7 0.2-1 0 0 0-0.1-0.1-0.1l0 0 -2.1-2.1c-2-2-5.3-2-7.4 0l-2.5 2.5c-2 2-2 5.3 0 7.3 0 0 0 0 0 0l2 2C29.6 42.2 30 42.2 30.3 41.9z"
                />
                <path
                  fill="#FFFFFF"
                  d="M50.7 27.6c0.1 0 0.1 0 0.2 0 3.4-1 7-1.5 10.6-1.5 3.4 0 6.9 0.5 10.2 1.4 0.4 0.1 0.8-0.1 0.8-0.5 0-0.1 0-0.1 0-0.2v-4.1c0-2.9-2.3-5.2-5.2-5.2H55.2c-2.9 0-5.2 2.3-5.2 5.2v4.2C49.9 27.3 50.3 27.6 50.7 27.6z"
                />
                <path
                  fill="#FFFFFF"
                  d="M61.4 30.3c-18.7 0-33.9 15.2-33.9 33.9 0 18.7 15.2 33.9 33.9 33.9 18.7 0 33.9-15.2 33.9-33.9C95.3 45.5 80.1 30.3 61.4 30.3zM61.4 88.1L61.4 88.1c-13.2-0.1-23.9-10.8-23.8-24 0-13.2 10.8-23.9 23.9-23.8 13.2 0 23.9 10.8 23.8 23.9C85.3 77.4 74.6 88.1 61.4 88.1z"
                />
                <path
                  fill="#FFFFFF"
                  d="M62.1 44.5c-0.4 0-0.7 0.3-0.7 0.7l0 0.1V63c0 0.4 0.3 0.7 0.7 0.7 0.1 0 0.2 0 0.3-0.1l15.8-8.2c0.4-0.2 0.5-0.6 0.3-1 0 0 0 0 0 0C75.1 48.5 68.9 44.7 62.1 44.5z"
                />
              </svg>
            </div>
            <div
              sx={{
                mt: 4,
                display: 'flex',
                flexDirection: 'row',
                '.ais-Panel': {
                  mr: [8, 16],
                },
                '.ais-Panel--noRefinement': {
                  display: 'none',
                },
              }}
            >
              <Panel>
                <FacetTitle>Language</FacetTitle>
                <RefinementList
                  attribute="lang"
                  transformItems={items =>
                    items.map(item => ({
                      ...item,
                      label: langLabelMap[item.label],
                    }))
                  }
                />
              </Panel>
              <Panel>
                <FacetTitle>Category</FacetTitle>
                <RefinementList attribute="category" />
              </Panel>
            </div>
            <Panel sx={{ mt: 4 }}>
              <FacetTitle>Series</FacetTitle>
              <RefinementList attribute="series" />
            </Panel>
            <Hits
              sx={{
                mt: [8, 16],
                '& .ais-Hits-list': {
                  listStyle: 'none',
                  m: 0,
                  p: 0,
                },
                '& .ais-Hits-item': {
                  mt: 8,
                },
              }}
              hitComponent={({ hit }) => (
                <Link
                  sx={{
                    color: 'inherit',
                    textDecoration: 'none',
                    '&:hover p': {
                      textDecoration: 'underline',
                    },
                  }}
                  to={getBlogUrl(hit.slug, hit.seriesSlug)}
                >
                  <div>
                    <p
                      sx={{
                        fontWeight: 'semibold',
                        fontSize: 'xl',
                        m: 0,
                      }}
                    >
                      {hit.title}
                    </p>

                    <div
                      sx={{
                        fontSize: 'xs',
                        opacity: 0.6,
                      }}
                    >
                      {format(hit.publishedAt, 'MMMM Do, YYYY')}
                    </div>
                  </div>
                </Link>
              )}
            />
          </InstantSearch>
        </div>
      </Container>
    </LayoutContainer>
  )
}

export default SearchPage
